@import '~antd/dist/antd.css';

body {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100%;
}

.ant-layout {
  background-color: #fff0;
}

.ant-layout-footer {
  background: none;
}

.ant-result {
  padding: 60px 32px;
  height: 100vh;
}

.form-header {
  font-weight: bold;
  text-align: center;
  color: #39a4db;
}

.form {
  margin: 60px 0;
  background: hsla(0, 0%, 100%, 0.3);
  padding: 10px 0;
}

@media screen and (max-width: 576px) {
  .ant-layout {
    background: #f0f2f5;
  }
  .form {
    margin: 0 0 5px;
  }
}
